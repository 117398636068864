* *::before,
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  /* colors */
  --primary-100: #dee9d8;
  --primary-200: #c3d6b8;
  --primary-500: #8db178;
  --primary-700: #8cb077;
  --primary-800: #729b5a;
  --primary-900: #5a7b47;

  --secondary-500: #d9d9d9;
  --secondary--800: #8a9099;

  --gray-500: #81848a;
  --gray-700: #969696;
  --danger: #d32f2f;

  --border: 1px solid #8a9099;
  --border-clr: #8a9099;
  --underline-clr: #999;
  /* fonts */
  --bodyFont: "Roboto", sans-serif;
}
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

body {
  font-family: var(--bodyFont);
  overflow-y: hidden;
  /* background-color: #292929;
  color: white; */
}


.active-label-box {
  background-color: var(--primary-200);
  color: black !important
}

.filter-box {
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid var(--primary-500);
  color: var(--primary-500);
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: none;
}

li {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* ::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  opacity: 0.7;
} */

.btn-primary {
  border-radius: 0.5rem;
  padding: 0.3rem;
  background-color: var(--primary-500);
}

.search-box {
  all: unset;
  border-radius: 0.5rem;
  width: 75%;
  padding: 0.5rem;
  background-color: #d9d9d9;
  border: none;
  padding-left: 3rem;
  padding-top: 0.6rem;
}

.search-container {
  position: relative;
}

.search-btn {
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 12px;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

/* utility class */

.dfc {
  display: flex;
  flex-direction: column;
}

.vertical-spacer-20 {
  height: 20px;
}

.vertical-spacer-10 {
  height: 10px;
}

.top-heading {
  font-size: 25px;
  font-weight: 500;
  line-height: 39px;
}

.variable-popup {
  width: 150px;
  height: 169px;
}
.variable-popup h5 {
  margin-left: 1px;
  font-size: 16px;
  margin-top: 8px;
  font: weight 500px;
}
.variable-popup span {
  display: flex;
  flex-direction: column;
}
.popup-list {
  font-size: "18px";
  margin-top: 5px;
}
.variable-popup .underline {
  margin-top: 10px;
  width: 300px;
  border-top: 1px solid;
  border-color: #999999;
  height: 10px;
}



/* Heading Css */
.theme-heading3-700 {
  font-family: "Roboto";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.theme-heading3-500 {
  font-family: "Roboto";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
}

.theme-heading3-400 {
  font-family: "Roboto";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}

.theme-heading4-700 {
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.theme-heading4-500 {
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.theme-heading4-400 {
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}


.theme-heading5-700 {
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.theme-heading5-500 {
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.theme-heading5-400 {
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}


.collapse-chat-container {
  /* width: calc(100% - 650px) !important; */
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@keyframes spinLoader {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* to hide increment decrement button in number input type */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type=number] {
  -moz-appearance: textfield;
}

.manualTicketBtn {
  background: lightgrey;
  /* margin: 10px 20px; */
  padding: 8px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}


.manual-Btn-Container{
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.createmanualTicket-form-field {
  margin-bottom: 25px;
}