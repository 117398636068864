  
  .customer-reason {
    flex: 1;  /* Allows the reason to grow and take up available space */
    /* white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;  */
    color: #545454;
    font-weight: 550;
    font-size: 14px;
  }
  
  .customer-sentiment {
    flex-shrink: 0;  
    display: flex;
    align-items: center;
    margin-top: 2px;
  }
  
  .sentiment-emoji {
    margin-right: 8px;
    /* font-size: 1.5em; */
  }
  
  @media (max-width: 600px) {
    .customer-sentiment {
      margin-left: 0;  /* Remove margin in stacked layout */
      margin-top: 8px;  /* Add spacing when stacked */
    }
  }
  